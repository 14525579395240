import CheckboxSelectAll from "@stimulus-components/checkbox-select-all";

export class BulkActionsController extends CheckboxSelectAll {
  static targets = ["checkbox", "checkboxAll", "output", "input"];
  static values = {
    disabled: { type: Boolean, default: false },
  };

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  connect() {
    super.connect();
    this._update();
  }

  toggle(event) {
    super.toggle(event);
    this._update();
  }

  refresh() {
    super.refresh();
    this._update();
  }

  _update() {
    this.outputTarget.value = this._ids();
    this._updateDisabledValue();
  }

  disabledValueChanged(value) {
    this.inputTargets.forEach((input) => {
      input.disabled = value;
    });
  }

  _updateDisabledValue() {
    this.disabledValue = this.checked.length === 0;
  }

  _ids() {
    return this.checked.map((checkbox) => checkbox.value);
  }
}
